.virus-danger-header-text {
    margin: auto;
    text-align: center;
    padding: 35px 20px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 46px;
    color: #09A2D7;
}

@media screen and (max-width: 1100px) {
    .virus-danger-header-text {
        width: 90%;
        line-height: 32px;
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (max-width: 700px) {
    .virus-danger-header-text {
        width: 92%;
        font-size: 22px;
        padding-top: 55px;
    }
}
