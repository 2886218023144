.header-bottom {
    height: 79px;
    width: 100%;
    background: linear-gradient(90deg, #09A2D7 35%, rgba(226, 155, 57, 0) 60%);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 6;
}

.header-bottom__logo {
    max-height: 102px;
    margin-top: -40px;
    left: 10%;
    z-index: 2;
    position: absolute;
}

.header-bottom__logo-background {
    position: absolute;
    left: 10px;
    top: -69px;
    z-index: 1;
    max-width: 100%;
}

.header-bottom__text {
    color: #F17728;
    margin-right: 200px;
    font-size: 30pt;
    font-weight: bold;
    letter-spacing: 0.01px;
}

.header-bottom__sun-logo-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
}

.header-bottom__sun-logo {
    max-width: 285px;
    max-height: 257px;
    margin-right: -2px;
    margin-top: -23px;
}

@media screen and (max-width: 1090px) {
    .header-bottom__text {
        margin-right: 130px;
    }

    .header-bottom__sun-logo {
        max-height: 175px;
        margin-top: -30px;
    }

    .header-bottom__logo-background {
        left: calc(0px - (1280px - 100vw) * 0.1);
    }
}

@media screen and (max-width: 975px) {
    .header-bottom__text {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .header-bottom {
        height: 120px;
        background: linear-gradient(90deg, #09A2D7 35%, rgba(226, 155, 57, 0) 81%);
    }

    .header-bottom__logo {
        max-height: 88px;
        margin-top: 0;
        left: 25px;
        z-index: 2;
        position: absolute;
    }

    .header-bottom__logo-background {
        left: -100px;
        top: -20px;
        z-index: 0;
    }

    .header-bottom__sun-logo {
        max-height: 217px;
        margin-top: 0px;
    }
}

@media screen and (max-width: 545px) {
    .header-bottom__sun-logo {
        max-height: 33vw;
    }
}

@media screen and (max-width: 500px) {
    .header-bottom__logo {
        width: 50%;
    }
}

@media screen and (max-width: 475px) {
    .header-bottom {
        height: 80px;
    }
}

@media screen and (max-width: 375px) {
    .header-bottom {
        height: 90px;
        background: linear-gradient(90deg, #09A2D7 58%, rgba(226, 155, 57, 0) 95%);
    }

    .header-bottom__logo {
        max-height: unset;
        width: 100%;
        max-width: 244px;
    }

    .header-bottom__logo-background {
        left: -18.7vw;
        top: -20px;
        z-index: 0;
    }

    .header-bottom__text {
        display: inline;
        margin-right: unset;
        max-width: 275px;
        position: absolute;
        bottom: -50px;
        left: 20px;
    }

    .header-bottom__sun-logo {
        max-height: unset;
        max-width: 50vw;
    }
}

@media screen and (max-width: 315px) {
    .header-bottom__text {
        max-width: calc(100% - 20px);
        left: 10px;
    }
}
