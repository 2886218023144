.footer-contact {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    padding-bottom: 36px;
    flex-wrap: wrap;
    padding-right: 20px;
    padding-left: 20px;
}

.footer-contact > .footer-contact__item:not(:first-child) {
    margin-left: 26px;
}

.footer-contact__item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.footer-contact__item > img {
    margin-right: 10px;
}

.footer-contact__item a {
    color: black;
}

@media screen and (max-width: 420px) {
    .footer-contact--ru {
        justify-content: flex-start;
    }

    .footer-contact--ru > .footer-contact__item {
        margin-left: 21px;
    } 
}

@media screen and (max-width: 400px) {
    .footer-contact {
        justify-content: flex-start;
    }

    .footer-contact > .footer-contact__item {
        margin-left: 21px;
    }    
}
