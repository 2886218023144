.stay-healthy-item {
    max-width: 250px;
    width: 100%;
    padding: 10px;
    text-align: center;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;;
    color: #000;
}

.stay-healthy-item--large {
    max-width: 320px;
}

.stay-healthy-item__header {
    margin-top: -20px;
    font-weight: bold;
    text-transform: uppercase;
}

.stay-healthy-item__img {
    max-width: 100%;
    margin: 20px 0;
}