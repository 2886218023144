.stay-healthy-footer {
    width: 100%;
    min-height: 65px;
    background-color: #09A2D7;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.stay-healthy-footer__text {
    padding: 20px 0;
    margin: 0 10px;
}

@media screen and (max-width: 375px) {
    .stay-healthy-footer__text {
        padding: 10px;
    }
}
